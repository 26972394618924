.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  background-image: radial-gradient( #cbcccb, #fff);
  font-family: 'Cormorant Garamond', serif;

}